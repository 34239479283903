export const ACTIVATE_TWO_FACTOR_AUTH = "/api/v3/sms_auth";
export const AUTO_CREATED_USER_UPDATE = "/api/v3/password/auto_created_user_update";
export const DECRYPT_EMAIL = "/api/v3/login/decrypt_email";
export const LOGIN = "/api/v3/login";
export const LOGOUT = "/logout";
export const RABBIT_SIGNUP = "/api/v3/rabbits";
export const REQUEST_RESET_PASSWORD = "/api/v3/password/send_reset";
export const RESEND_TWO_FACTOR_AUTH_CODE = "/api/v3/sessions/resend_sms_auth_code";
export const RESET_PASSWORD = "/api/v3/password/reset.json";
export const CHECK_RESET_PASSWORD_TOKEN = "/api/v3/password/check_reset_token.json";
export const SEND_TWO_FACTOR_CODE = "/api/v3/sms_auth/phone";
export const SIGNUP = "/api/v3/users";
export const UPDATE_PASSWORD = "/api/v3/password/update.json";
