import React, { useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { reduxForm, Field } from "redux-form";
import { defineMessages } from "react-intl";
import { FormattedMessage } from "components/FormattedMessage";
import { connect, useSelector } from "react-redux";
import { requestResetPassword } from "login/redux/modules/loginSignup";
import {
  createValidator,
  email as emailValidation,
  required,
} from "util/validation";
import qs from "qs";
import { LoginTextField } from "login/components/LoginInputFields";
import Typography from "@taskrabbit/meadow-web/lib/Typography";
import ButtonPrimary from "@taskrabbit/meadow-web/lib/ButtonPrimary";
import SegmentAnalyticsService from "services/SegmentAnalyticsService";
import { PASSWORD_RESET } from "enums/segmentEventNames";

const messages = defineMessages({
  title: {
    id: "ChangePasswordV2.enterEmail.title",
    defaultMessage: "Reset Password",
  },
  description: {
    id: "ChangePasswordV2.enterEmail.description",
    defaultMessage:
      "Enter your email and we'll send you instructions to reset your password",
  },
  tokenExpireDescription: {
    id: "ChangePasswordV2.enterEmail.tokenExpireDescription",
    defaultMessage:
      "The link to set your password has expired. Enter your email and we’ll send you a new link to continue.",
  },
  cta: {
    id: "ChangePasswordV2.enterEmail.cta",
    defaultMessage: "Reset password",
  },
  email: {
    id: "password.form.labels.email",
    defaultMessage: "Email Address",
  },
  partnerTitle: {
    id: "ChangePasswordV2.enterEmail.partnerTitle",
    defaultMessage: "Set Password",
  },
  partnerDescription: {
    id: "ChangePasswordV2.enterEmail.partnerDescription",
    defaultMessage:
      "Enter your email and we'll send you instructions to set your password",
  },
  partnerCta: {
    id: "ChangePasswordV2.enterEmail.partnerCta",
    defaultMessage: "Set password",
  },
  partnerSendLinkCta: {
    id: "ChangePasswordV2.enterEmail.partnerSendLinkCta",
    defaultMessage: "Send link",
  },
});

const ForgottenPasswordRequest = ({
  email,
  requestResetPasswordAction,
  handleSubmit,
  tokenExpired
}) => {
  const hasEmailValue = useSelector(
    (state) => state.form.reset_password_form?.values?.email
  );
  const hasEmailError = useSelector(
    (state) => state.form.reset_password_form?.syncErrors?.email
  );
  const isEmailValid = hasEmailValue && !hasEmailError;

  useEffect(() => {
    SegmentAnalyticsService.trackPage({
      name: PASSWORD_RESET,
    });
  }, []);

  return (
    <div>
      <meta name="referrer" content="origin" />
      <h3 className="login-signup-v2-title">
        <FormattedMessage message={messages.partnerTitle} />
      </h3>
      <form style={{ width: "100%" }} className="login-signup-v2">
        <fieldset className="u-align--center">
          <div className="set-password-instructions">
            <Typography variant="body3">
              <FormattedMessage
                message={tokenExpired ? messages.tokenExpireDescription : messages.partnerDescription}
                values={{ email }}
              />
            </Typography>
          </div>
        </fieldset>
        <fieldset className="login-signup-v2-item-label">
          <div className="login-signup-v2-label">
            <Typography variant="label3">
              <FormattedMessage message={messages.email} />
            </Typography>
          </div>
          <Field
            component={LoginTextField}
            id="email"
            placeholder="123456789"
            name="email"
            type="text"
            messageId={messages.email.id}
            data-ref="email-input"
          />
        </fieldset>
        <fieldset className="login-signup-v2-item login-set-password-v2">
          <ButtonPrimary
            data-ref="reset-password"
            disabled={!isEmailValid}
            fullWidth={true}
            onClick={handleSubmit(requestResetPasswordAction)}
            type="submit"
          >
            <FormattedMessage message={tokenExpired ? messages.partnerSendLinkCta : messages.partnerCta} />
          </ButtonPrimary>
        </fieldset>
      </form>
    </div>
  );
};

const resetValidator = createValidator({
  email: [required, emailValidation],
});

const mapStateToProps = (state, ownProps) => {
  const {
    location: { search },
  } = ownProps;
  const query =
    search !== "" ? qs.parse(search, { ignoreQueryPrefix: true }) : {};

  return {
    tokenExpired: query.tokenExpired,
  };
};

export default hot(
  connect(mapStateToProps, {
    requestResetPasswordAction: requestResetPassword,
  })(
    reduxForm({
      form: "reset_password_form",
      validate: resetValidator,
    })(ForgottenPasswordRequest)
  )
);
