import React, { useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { defineMessages, injectIntl } from "react-intl";
import { Link } from "react-router-dom";

import { FormattedMessage } from "components/FormattedMessage";
import { localeConfig } from "config/localeDriven";
import { internalPath } from "util/internalPath";
import SegmentAnalyticsService from "services/SegmentAnalyticsService";
import { SIGN_UP } from "enums/segmentEventNames";

const messages = defineMessages({
  poster: {
    id: "loginSignup.labels.intent.poster",
    defaultMessage: "I need help with tasks",
  },
  tasker: {
    id: "loginSignup.labels.intent.tasker",
    defaultMessage: "I want to be a Tasker",
  },
  sub: {
    id: "loginSignup.labels.intent.sub",
    defaultMessage: "Becoming a tasker requires a background check",
  },
});

const SignupIntent = (props) => {
    const becomeATaskerUrl = localeConfig(
      props.intl.locale,
      "localeRoutes.becomeATasker.url"
    );

    useEffect(() => {
      SegmentAnalyticsService.trackPage({
        name: SIGN_UP,
      });
    }, []);

    return (
      <div className="login-signup-v2">
        <Link to="/social" className="btn btn-intent btn-primary u-full-width login-signup-v2-item">
          <FormattedMessage message={messages.poster} />
        </Link>
        <a
          href={internalPath(becomeATaskerUrl)}
          className="btn btn-intent btn-secondary u-full-width login-signup-v2-item"
        >
          <FormattedMessage message={messages.tasker} />
        </a>
        <small className="u-align--center intent-sub login-signup-v2-item">
          <FormattedMessage message={messages.sub} />
        </small>
      </div>
    );
};

export default hot(injectIntl(SignupIntent));
