import React, { useEffect } from "react";
import styled from "styled-components";
import { hot } from "react-hot-loader/root";
import { defineMessages, injectIntl } from "react-intl";
import { FormattedMessage } from "components/FormattedMessage";
import Typography from "@taskrabbit/meadow-web/lib/Typography";
import SegmentAnalyticsService from "services/SegmentAnalyticsService";
import { PASSWORD_RESET } from "enums/segmentEventNames";

const messages = defineMessages({
  confirm_title: {
    id: "password.form.reset.confirm_title",
    defaultMessage: "Check your Email.",
  },
  confirm_paragraph: {
    id: "password.form.reset.confirm_paragraph",
    defaultMessage:
      "We have sent instructions to set your password. Please update within the next hour or you will need to request a new link to set your password.",
  },
  confirm_thank_you: {
    id: "password.form.reset.confirm_thank_you",
    defaultMessage: "Thank you!",
  },
});

const ConfirmText = styled.p`
  padding: 0 16px;
`;

const ChangePasswordConfirm = () => {
  useEffect(() => {
    SegmentAnalyticsService.trackPage({
      name: PASSWORD_RESET,
    });
  }, []);

  return (
    <div className="change-password-item">
      <Typography variant="h3" className="change-password-title">
        <FormattedMessage message={messages.confirm_title} />
      </Typography>
      <ConfirmText className="change-password-paragraph__text">
        <FormattedMessage message={messages.confirm_paragraph} />
      </ConfirmText>
      <Typography variant="h4" className="change-password-subtitle">
        <FormattedMessage message={messages.confirm_thank_you} />
      </Typography>
    </div>
  );
}

export default hot(injectIntl(ChangePasswordConfirm));
