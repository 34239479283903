import LoginLayout from "login/containers/LoginLayout";
import ChangePassword from "login/components/ChangePassword/ChangePassword";
import ChangePasswordConfirm from "login/components/ChangePassword/ChangePasswordConfirm";
import ForgottenPasswordRequest from "login/components/ChangePassword/ForgottenPasswordRequest";
import SignupIntent from "login/components/Signup/SignupIntent";
import LoginChooser from "login/components/Login/LoginChooser";
import LoginOrSignup from "login/components/LoginOrSignup";
import SignupChooser from "login/components/Signup/SignupChooser";

export default [
  {
    component: LoginLayout,
    path: "/",
    routes: [
      {
        path: "/:trCountry?/:trLanguage?/(login)",
        exact: true,
        component: LoginOrSignup,
      },
      {
        path: "/:trCountry?/:trLanguage?/login/email",
        exact: true,
        component: LoginChooser,
      },
      {
        path: "/:trCountry?/:trLanguage?/password/reset",
        component: ForgottenPasswordRequest,
        exact: true,
      },
      {
        path: "/:trCountry?/:trLanguage?/password/change",
        component: ChangePassword,
        exact: true,
      },
      {
        path: "/:trCountry?/:trLanguage?/password/confirm",
        component: ChangePasswordConfirm,
        exact: true,
      },
      {
        path: "/:trCountry?/:trLanguage?/signup/password",
        exact: true,
        component: SignupChooser,
      },
      {
        path: "/:trCountry?/:trLanguage?/signup(/intent)?",
        exact: true,
        component: SignupIntent,
      },
    ],
  },
];
