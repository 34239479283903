import React, { useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { reduxForm, Field } from "redux-form";
import { defineMessages, useIntl } from "react-intl";
import {
  FormattedHTMLMessage,
  FormattedMessage,
} from "components/FormattedMessage";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import {
  checkResetPasswordToken,
  resetPassword,
} from "login/redux/modules/loginSignup";
import { ACCOUNT_PASSWORD } from "enums/segmentEventNames";
import qs from "qs";
import { LoginTextField } from "login/components/LoginInputFields";
import ButtonPrimary from "@taskrabbit/meadow-web/lib/ButtonPrimary";
import classnames from "classnames";
import { Row, Col } from "components/Grid";
import { LoadingSpinner } from "components/LoadingSpinner";
import { passwordValidator } from "util/validation";
import Typography from "@taskrabbit/meadow-web/lib/Typography";
import { localeConfig } from "config/localeDriven";
import countryIsoCodeFromLocale from "util/countryIsoCodeFromLocale";
import globalMessages from "messages";
import Grid from "@taskrabbit/meadow-web/lib/Grid";
import SegmentAnalyticsService from "services/SegmentAnalyticsService";
import { PASSWORD_RESET } from "enums/segmentEventNames";

const messages = defineMessages({
  title: {
    id: "ChangePasswordV2.enterNewPassword.title",
    defaultMessage: "Change my password.",
  },
  description: {
    id: "ChangePasswordV2.enterNewPassword.description",
    defaultMessage:
      "Enter a new password that's different than the one you've previously used or that you've used on other sites",
  },
  cta: {
    id: "ChangePasswordV2.enterNewPassword.cta",
    defaultMessage: "Set my password",
  },
  password: {
    id: "password.form.labels.password",
    defaultMessage: "Password",
  },
  confirmPassword: {
    id: "password.form.labels.confirmPassword",
    defaultMessage: "Confirm Password",
  },
  email: {
    id: "ChangePasswordV2.form.labels.email",
    defaultMessage: "Email",
  },
  placeholder: {
    id: "ChangePasswordV2.form.labels.placeholder",
    defaultMessage: "ex. 35o234a",
  },
  passwordLength: {
    id: "ChangePasswordV2.form.labels.passwordLength",
    defaultMessage: "Please use 8 characters or more for your password",
  },
  passwordMatch: {
    id: "ChangePasswordV2.form.labels.passwordMatch",
    defaultMessage: "Password and confirmation did not match",
  },
  consent: {
    id: "ChangePasswordV2.form.labels.consent",
    defaultMessage:
      'By clicking below and creating an account, I confirm that I agree to Taskrabbit\'s <a target="_blank" href="{termsUrl}">Terms of Use</a> and have read the <a target="_blank" href="{privacyUrl}">Privacy Policy</a>.',
  },
});

const ChangePassword = ({
  email,
  token,
  pushAction,
  resetPasswordAction,
  checkResetPasswordTokenAction,
  handleSubmit,
  loading,
  prechecking,
  errorMessage,
  arePasswordsValid,
}) => {
  const { locale } = useIntl();
  const privacyUrl = localeConfig(locale, "localeRoutes.privacy.url");
  const termsUrl = localeConfig(locale, "localeRoutes.terms.url");

  const isEUCountry = ["FR", "GB", "DE", "ES", "PT", "IT"].includes(
    countryIsoCodeFromLocale(locale)
  );
  const isPhaseArticlePricing = window.PHASE_ARTICLE_PRICING;
  const displayBasicConsent = !isPhaseArticlePricing || !isEUCountry;

  useEffect(() => {
    SegmentAnalyticsService.trackPage({
      name: PASSWORD_RESET,
    });
  }, []);

  useEffect(() => {
    if (token) {
      checkResetPasswordTokenAction({ token });
    } else {
      // redirect to /login if loading without token
      pushAction("/login");
    }
  }, [token, pushAction, checkResetPasswordTokenAction]);

  useEffect(() => {
    SegmentAnalyticsService.trackPage({
      name: ACCOUNT_PASSWORD,
      properties: {},
    });
  }, []);

  const resetPasswordButtonClasses = classnames({
    "u-full-width": true,
    "is-loading-meadow": loading,
  });

  return prechecking ? (
    <LoadingSpinner />
  ) : (
    <div>
      <meta name="referrer" content="origin" />
      <div
        className="u-align--center"
        style={{ padding: "0 1em", margin: "2em 0 0", fontSize: "14px" }}
      >
        <FormattedMessage message={messages.description} />
      </div>
      <form style={{ width: "100%" }} className="login-signup-v2">
        <div className="login-signup-v2-item">
          <div className="login-signup-v2-first-label">
            <Typography variant="label3">
              <FormattedMessage message={messages.email} />
            </Typography>
          </div>
          <fieldset>
            <input
              className="password-confirmation-input__email"
              disabled={true}
              value={email}
              id="email"
              name="email"
              data-test-id="email"
              type="email"
            />
          </fieldset>
        </div>

        <div className="login-signup-v2-item-label">
          <div className="login-signup-v2-label">
            <Typography variant="label3">
              <FormattedMessage message={messages.password} />
            </Typography>
          </div>
          <fieldset>
            <Field
              component={LoginTextField}
              id="password"
              name="password"
              type="password"
              messageId={messages.placeholder.id}
              data-ref="password"
            />
          </fieldset>
        </div>

        <div className="login-signup-v2-item-label">
          <div className="login-signup-v2-label">
            <Typography variant="label3">
              <FormattedMessage message={messages.confirmPassword} />
            </Typography>
          </div>
          <fieldset>
            <Field
              component={LoginTextField}
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              messageId={messages.placeholder.id}
              data-ref="confirmPassword"
            />
          </fieldset>
        </div>

        {errorMessage && (
          <Row className="exterior__bottom--md">
            <Col size={12}>
              <div className="field-error-container-blue">
                {errorMessage.message}
              </div>
            </Col>
          </Row>
        )}

        {isPhaseArticlePricing && isEUCountry && (
          <div className="login-signup-v2-item">
            <Grid container={true} spacing={2}>
              <Grid item={true} sm={1}>
                <Field
                  component="input"
                  id="termsAccepted"
                  name="termsAccepted"
                  type="checkbox"
                  data-ref="termsAccepted"
                />
              </Grid>
              <Grid item={true} sm={11}>
                <FormattedHTMLMessage
                  message={globalMessages.termsConsentExplicit}
                  values={{
                    termsUrl,
                    privacyUrl,
                  }}
                />
              </Grid>
              <Grid item={true} sm={1}>
                <Field
                  component="input"
                  id="notificationsOptOut"
                  name="notificationsOptOut"
                  type="checkbox"
                  data-ref="notificationsOptOut"
                />
              </Grid>
              <Grid item={true} sm={11}>
                <FormattedHTMLMessage
                  message={globalMessages.marketingConsent}
                />
              </Grid>
            </Grid>
          </div>
        )}

        {displayBasicConsent && (
          <div className="login-signup-v2-item-label">
            <div className="consent-text">
              <FormattedHTMLMessage
                message={messages.consent}
                values={{
                  termsUrl,
                  privacyUrl,
                }}
              />
            </div>
          </div>
        )}

        <fieldset className="fields-submit login-signup-v2-item">
          {/* [MEADOW_TODO] - Button */}
          <ButtonPrimary
            className={resetPasswordButtonClasses}
            data-ref="reset-password"
            disabled={loading || !arePasswordsValid}
            fullWidth={true}
            onClick={handleSubmit(resetPasswordAction)}
            sx={{ margin: 0 }}
            type="submit"
            style={{ marginTop: "0.875em" }}
          >
            <FormattedMessage message={messages.cta} />
          </ButtonPrimary>
        </fieldset>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { values, registeredFields } = state.form.change_password_form || {};
  const hasPasswordValue = values?.password;
  const hasConfirmPasswordValue = values?.confirmPassword;

  const hasPasswordError = state.form.reset_password_form?.syncErrors?.password;
  const hasConfirmPasswordError =
    state.form.reset_password_form?.syncErrors?.confirmPassword;

  const isPasswordValid = hasPasswordValue && !hasPasswordError;
  const isConfirmPasswordValid =
    hasConfirmPasswordValue && !hasConfirmPasswordError;
  let hasTermsAccepted = values?.termsAccepted;

  if (registeredFields?.termsAccepted?.name === undefined) {
    hasTermsAccepted = true;
  }
  const arePasswordsValid =
    isPasswordValid && isConfirmPasswordValid && hasTermsAccepted;

  const { loading, errorPayload, prechecking } = state.loginSignup.login;

  const {
    location: { search },
  } = ownProps;
  const query =
    search !== "" ? qs.parse(search, { ignoreQueryPrefix: true }) : {};

  const queryData = { token: query.token, email: query.email };
  return {
    initialValues: { ...queryData },
    ...queryData,
    loading,
    prechecking,
    errorMessage: errorPayload,
    arePasswordsValid,
  };
};

export default hot(
  connect(mapStateToProps, {
    resetPasswordAction: resetPassword,
    checkResetPasswordTokenAction: checkResetPasswordToken,
    pushAction: push,
  })(
    reduxForm({
      form: "change_password_form",
      validate: passwordValidator,
    })(ChangePassword)
  )
);
