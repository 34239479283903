import React from "react";
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';

import AlertContainer from "alerts/containers/AlertContainer";
import { renderRoutes, RouteConfigComponentProps } from "react-router-config";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import backgroundImg from "web/solo_assembly_new_2023.png";
import TRLogo from "components/Logos/TRLogo";

import Container from "@taskrabbit/meadow-web/lib/Container";

const StyledContainer = styled(Container)(({
  theme: {
    breakpoints
  }
}) => ({
  [breakpoints.down('md')]: {
    padding: 0,
  },
  [breakpoints.up('md')]: {
    maxWidth: "100%",
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: "100vw",
    height: "100vh",
  },
  display: "inline-block",
}))

const StyledModal = styled(Container)(({
  theme: {
    breakpoints,
    meadow: {
      purpose
    } }
}) => ({
  [breakpoints.down('md')]: {
    height: "100vh",
  },
  [breakpoints.up('md')]: {
    maxWidth: "500px",
    marginTop: "10.25em"
  },
  backgroundColor: purpose.component.main,
  borderRadius: purpose.borderRadius.floating,
  border: purpose.outline.subtle,
}))

const LoginLayout = ({ route }: RouteConfigComponentProps) => (
  <StyledContainer>
    <StyledModal>
      <AlertContainer key="alerts" />
      <Container sx={{
        display: "inline-flex",
        margin: "2.5em auto auto",
        height: {
          xs: "44px",
          sm: "55px",
        },
        padding: {
          xs: "0",
          md: "0 16px",
        }
      }}>
        <TRLogo type={"full"} style={{ marginLeft: "auto", marginRight: "auto" }} />
      </Container>
      <GoogleReCaptchaProvider reCaptchaKey={window.RECAPTCHA_SITE_KEY}>
        {renderRoutes(route?.routes)}
      </GoogleReCaptchaProvider>
    </StyledModal>
  </StyledContainer>
)

export default LoginLayout;
